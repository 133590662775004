import React, { createContext, useContext, useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import useHistoryParams from './useHistoryParam';

// Create the PromoContext
const PromoContext = createContext();

// Custom hook to use PromoContext
export const usePromo = () => useContext(PromoContext);

// PromoProvider component
export const PromoProvider = ({ children }) => {
  const { params, updateParams } = useHistoryParams();
  const [promo, setPromo] = useState(0.5);

  useEffect(() => {
    const pixelParam = params.get('p'); // Retrieve 'p' parameter
    const promoAmount = params.get('promo'); // Retrieve 'promo' parameter

    // Initialize Facebook Pixel if pixelParam exists
    if (pixelParam) {
      ReactPixel.init(pixelParam);
      console.log('Facebook Pixel initialized with:', pixelParam);
    }

    // Update state with promo details if promoAmount exists
    if (promoAmount) {
      setPromo((100-promoAmount)/100);
      console.log('Promo amount set to:', (100-promoAmount)/100);
    }

    // Remove params from URL if they exist
    if (pixelParam || promoAmount) {
      updateParams({ p: null, promo: null });
    }
  }, [params, updateParams]);

  return (
    <PromoContext.Provider value={promo}>
      {children}
    </PromoContext.Provider>
  );
};
