import React, { useState, useEffect } from 'react';
import CountdownTimer from './CountDownTimer';
import { usePromo } from '../providers/Init';

const PromoOverlay = () => {

  const today = new Date();
  const todayDay = new Intl.DateTimeFormat("zh-Hant", { weekday: "long" }).format(today);
  const promo = usePromo();
  
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);

  // Check local storage to determine if the overlay should show
  useEffect(() => {
    const isPromoDismissed = localStorage.getItem('promoDismissed');
    if (!isPromoDismissed) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsFading(true); // Start fade-out effect
    setTimeout(() => {
      setIsVisible(false); // Remove overlay after animation
      localStorage.setItem('promoDismissed', 'true'); // Save dismissal in local storage
    }, 500); // Match duration with CSS transition
  };

  return (
    isVisible && (
      <div
        className={`fixed flex items-center z-50 justify-center w-full h-screen p-8 bg-black backdrop-blur-md bg-opacity-60 transition-opacity duration-500 ${
          isFading ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <div className="w-full h-auto max-w-md overflow-hidden bg-white shadow-lg rounded-xl">
        <div className="relative flex items-center w-full">
        <div className="absolute flex flex-col left-4">
            <span className="text-3xl  font-black uppercase text-[#FF2B85]">Tasty</span>
            <span className="text-3xl  font-black uppercase text-[#a11e54]">{todayDay}</span>
            
        </div>

        <img src="/promo.png" alt="" />
      </div>
          <div className="p-6 text-center">
            <h2 className="text-3xl font-bold text-[#FF2B85]">🎉 歡樂時光！🎉</h2>
            <p className="mt-4 text-lg text-gray-700">
              Enjoy <span className="font-bold text-[#a11e54]">-{100 - (promo * 100)}% 折扣</span>，全場購物均適用！
            </p>
            <p className="mt-2 text-gray-500">快點，優惠在計時器結束時截止！⏳</p>
            <CountdownTimer />
            <button
              className="w-full px-6 py-3 mt-6 text-lg font-semibold text-white bg-[#FF2B85] rounded-lg hover:bg-[#a11e54]"
              onClick={handleClose}
            >
              立即購買
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PromoOverlay;
